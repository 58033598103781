import React from "react"

export default function ObjectsItemDetailsComponent(props) {
  return (
    <div>
      <div>
        <span>open hours:{props.config.openHours}</span>
        <span>phone number:{props.config.phoneNumber}</span>
      </div>
    </div>
  )
}
