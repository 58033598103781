import React from "react"

export default function ProductsItemDetailsComponent(props) {
  return (
    <div>
      <div>
        <span>price:{props.config.price}</span>
        <span>minutes:{props.config.serviceTimeInterval.minutes}</span>
      </div>
    </div>
  )
}
