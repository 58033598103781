import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { sendRequest } from "../../services/requestTool"
import DefaultLoaderComponent from "./DefaultLoaderComponent"
import ObjectsItemDetailsComponent from "../configure/ObjectsItemDetailsComponent"
import ProductsItemDetailsComponent from "../configure/ProductsItemDetailsComponent"
import WorkersItemDetailsComponent from "../configure/WorkersItemDetailsComponent"

const components = {
  objects: ObjectsItemDetailsComponent,
  products: ProductsItemDetailsComponent,
  workers: WorkersItemDetailsComponent,
}

const ObjectItemWrapper = styled.div`
  border: 1px solid rgba(33, 33, 33, 0.7);
  display: flex;
  margin: 10px 0;
  padding: 5px;
  width: 100%;
  cursor: pointer;
`

const ImageContainer = styled.div`
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ObjectImage = styled.img`
  margin: 0;
`

const ListItemRightSideWrapper = styled.div`
  padding: 5px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const ListItemHeader = styled.h5`
  margin: 0;
`

const DetailsSectionWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
`

export default function ConfigureSectionBigListItem(props) {
  const [pathToImg, setPath] = useState("")
  let [color, setColor] = useState("blue")

  useEffect(() => {
    let mounted = true
    sendRequest({ url: "http://spoomyfly.com/api/File=80x80" }).then(res => {
      setPath(res.data)
    })
    return () => (mounted = false)
  }, [])

  return (
    <ObjectItemWrapper>
      <ImageContainer>
        {pathToImg != "" ? (
          <ObjectImage src={pathToImg} />
        ) : (
          <DefaultLoaderComponent size={30} />
        )}
      </ImageContainer>
      <ListItemRightSideWrapper>
        <ListItemHeader>{props.config.itemTitle}</ListItemHeader>
        {props.config.detailsSectionComponentKeyName ? (
          <DetailsSectionWrapper>
            {(() => {
              return React.createElement(
                components[props.config.detailsSectionComponentKeyName],
                {
                  config: props.config,
                }
              )
            })()}
          </DetailsSectionWrapper>
        ) : (
          ""
        )}
      </ListItemRightSideWrapper>
    </ObjectItemWrapper>
  )
}
