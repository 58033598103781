import React from "react"

export default function ProductsItemDetailsComponent(props) {
  return (
    <div>
      <div>
        <span>phone number:{props.config.phoneNumber}</span>
        <span>email:{props.config.email}</span>
      </div>
    </div>
  )
}
