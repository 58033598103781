import React, { useEffect, useState } from "react"
import { sendRequest } from "../../services/requestTool"
import ConfigureSectionBigListItem from "./ConfigureSectionBigListItem"
import ConfigureSectionSmallListItem from "./ConfigureSectionSmallListItem"
import DefaultLoaderComponent from "./DefaultLoaderComponent"
import styled from "styled-components"

const components = {
  big: ConfigureSectionBigListItem,
  small: ConfigureSectionSmallListItem,
}

const ConfigureSectionWrapperDiv = styled.div`
  width: 600px;
  margin: 0 auto;
  margin-bottom: 20px;
`

const ObjectsListItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const SectionTitleHeader = styled.h4`
  font-weight: 400;
  font-size: 24px;
  margin-bottom: 10px;
`

function ConfigureSectionWrapper(props) {
  const [list, setList] = useState(null)

  useEffect(() => {
    let mounted = true
    sendRequest({ url: props.config.apiItemsEndpoint }).then(res => {
      console.log(
        "OUTPUT ~ file: configureSectionWrapper.js ~ line 43 ~ sendRequest ~ res",
        res
      )
      // let tmpArr = []
      // const obj = res.data
      // for (let i = 0; i < 10; i++) {
      //   tmpArr.push(res.data)
      // }
      // setList(tmpArr)

      setList(res.data[0] ? res.data : [res.data])
    })
    return () => (mounted = false)
  }, [])

  return (
    <>
      <ConfigureSectionWrapperDiv>
        <SectionTitleHeader>{props.config.sectionTitle}</SectionTitleHeader>
        {(() => {
          if (list == null) {
            return (
              <LoaderWrapper>
                <DefaultLoaderComponent
                  size={70}
                  desc={props.config.loaderDescription}
                />
              </LoaderWrapper>
            )
          } else {
            return (
              <ObjectsListItemsWrapper>
                {list.map((e, index) =>
                  (() => {
                    return React.createElement(
                      components[props.config.listItemType],
                      {
                        config: {
                          ...props.config.apiObjectMapFunction(e),
                          ...props.config,
                        },
                        key: { index },
                      }
                    )
                  })()
                )}
              </ObjectsListItemsWrapper>
            )
          }
        })()}
      </ConfigureSectionWrapperDiv>
    </>
  )
}

export default ConfigureSectionWrapper
