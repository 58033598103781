import React from "react"
import { Link } from "gatsby"
import AdminLayout from "../../components/adminPanelLayout"
import ConfigureSectionWrapper from "../../components/shared/configureSectionWrapper"
import styled from "styled-components"
import SEO from "../../components/seo"

const ConfigurePageTitle = styled.h2`
  text-align: center;
  font-weight: 400;
`
const ObjectDetailsSectionComponent = () => {
  return <div>details div</div>
}
const AdminPanel = () => (
  <AdminLayout>
    <SEO title="Page two" />
    <ConfigurePageTitle>Konfiguracja systemu</ConfigurePageTitle>
    <ConfigureSectionWrapper
      config={{
        sectionTitle: "Obiekty",
        apiItemsEndpoint: "http://spoomyfly.com/api/WorkObject/GetObjects",
        loaderDescription: "Ładowanie listy obiektów...",
        listItemType: "big",
        detailsSectionComponentKeyName: "objects",
        apiObjectMapFunction: e => {
          return {
            ...e,
            itemTitle: e.city,
          }
        },
      }}
    ></ConfigureSectionWrapper>
    <ConfigureSectionWrapper
      config={{
        sectionTitle: "Usługi",
        apiItemsEndpoint: "http://spoomyfly.com/api/Services/GetServices",
        loaderDescription: "Ładowanie listy usług...",
        listItemType: "big",
        detailsSectionComponentKeyName: "products",
        apiObjectMapFunction: e => {
          return {
            ...e,
            itemTitle: e.description,
          }
        },
      }}
    ></ConfigureSectionWrapper>
    <ConfigureSectionWrapper
      config={{
        sectionTitle: "Pracownicy",
        apiItemsEndpoint: "https://spoomyfly.com/api/Workers/GetAllWorkers",
        loaderDescription: "Ładowanie listy pracowników...",
        listItemType: "big",
        detailsSectionComponentKeyName: "workers",
        apiObjectMapFunction: e => {
          return {
            ...e,
            itemTitle: `${e.name} ${e.surname}`,
          }
        },
      }}
    ></ConfigureSectionWrapper>
    <ConfigureSectionWrapper
      config={{
        sectionTitle: "Interwały",
        apiItemsEndpoint:
          "https://spoomyfly.com/api/CompanyInfo/GetIntervalsConfig",
        loaderDescription: "Ładowanie interwałów...",
        listItemType: "small",
        apiObjectMapFunction: e => {
          return {
            ...e,
          }
        },
      }}
    ></ConfigureSectionWrapper>
    <ConfigureSectionWrapper
      config={{
        sectionTitle: "Płatności",
        apiItemsEndpoint: "http://spoomyfly.com/api/Payments/GetPaymentConfig",
        loaderDescription: "Ładowanie płatności...",
        listItemType: "small",
        apiObjectMapFunction: e => {
          return {
            ...e,
          }
        },
      }}
    ></ConfigureSectionWrapper>
    <ConfigureSectionWrapper
      config={{
        sectionTitle: "Dane kontaktowe",
        apiItemsEndpoint: "http://spoomyfly.com/api/Payments/GetPaymentConfig",
        loaderDescription: "Ładowanie danych kontaktowych...",
        listItemType: "small",
        apiObjectMapFunction: e => {
          return {
            ...e,
          }
        },
      }}
    ></ConfigureSectionWrapper>
  </AdminLayout>
)

export default AdminPanel
